export const rules = [
    (v:any) => !!v || 'Required',
    (v:any) => {
        const pattern = /^\d+$/
        return pattern.test(v) || 'Invalid input.'
    },
    (v:any) =>
        (v && v.length <= 100) ||
        "Text must be 100 characters or less"
]



